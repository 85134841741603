a {
    color: white;
    text-decoration: none;
}
.page {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    width: 100vw;
}

html, body {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}

