.challenge p {
    font-size: 1.2rem;
}

.challenge__header {
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.55),
        rgba(0, 0, 0, 0.55)
        ),
        url(https://we-are-hiring.cdn.personio.de/career-site/header/12616/16c9ffde310d2895f8af45872171e796jpeg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        min-height: 40vh;
        margin-bottom: 4rem;
}

.challenge__logo-wrapper {
    width: 15rem;
    height: 10rem;
    background-color: white;
    margin: 1rem auto;
    position: relative;
}

.challenge__logo-wrapper img {
    max-width: 12rem;
    max-height: 7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.challenge__header-content {
    margin: auto;
    text-align: center;
    padding-bottom: 1.5rem;
}

.challenge__title.title {
    font-size: 2.4rem;
    color: #fff;
}

.challenge__position.title {
    font-size: 2.2rem;
    color: #D8D8D8;
}

.challenge__download-button.button {
    white-space: pre;
    height: unset;
    display: inline-block;
    padding: 0.9rem 1.5rem;
    background-color: #3a3a3a;
}

.challenge__download-button.button .title {
    margin-bottom: 0.5rem;
    color: #fff;
}

.challenge__button-subtitle {
    color: #D8D8D8;
}
